@use '@angular/material' as mat;
@use 'palette' as p;
// Note: <a> elements with angular material button directives (mat-raised-button, mat-button, mat-icon-button, etc) will also have styles applied from button.scss
a {
  color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
  cursor: pointer;
  font-family: 'Roboto';
  text-decoration-line: none;
}

.a-like {
  color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
  cursor: pointer;

  &.underline {
    text-decoration-line: underline;
  }
}
