@use '@angular/material' as mat;
@use '../palette' as p;
@use '../typography/variables' as v;

.hide-panel {
  &.mat-mdc-select-panel {
    display: none;
  }
}

