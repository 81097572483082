@use '@angular/material' as mat;
@use '../palette' as p;

mat-chip-grid,
mat-chip-listbox {
  mat-chip-row,
  mat-chip,
  mat-chip-option {
    white-space: pre-wrap;

    &.mat-mdc-chip {
      &.blue {
        background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);

        .mat-mdc-chip-action-label {
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        }

        .mat-mdc-chip-remove {
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        }
      }

      &.mat-mdc-chip-selected {
        &.selected-blue {
          background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);

          .mat-mdc-chip-remove {
            color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
          }
        }
      }

      &.red {
        background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-error, p.$default-hue);
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);

        .mat-mdc-chip-action-label {
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        }
      }

      &.green {
        background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-primary, p.$default-hue);
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
      }

      &.grey {
        background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-grey, p.$default-hue);
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);

        .mat-mdc-chip-action-label {
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        }

        .mat-chip-remove {
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        }
      }

      &.no-hover {
        &:hover {
          &::after {
            opacity: 0;
          }
        }

        &::after {
          opacity: 0;
        }
      }
    }
  }
}
