@use '@angular/material' as mat;
@use '../palette' as p;

mat-stepper {
  &[orientation='vertical'] {
    &.blue {
      .mat-step-icon-selected,
      .mat-step-icon-state-edit {
        background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
      }
    }
  }
}

mat-stepper {
  &.blue {
    .mat-step-icon-selected,
    .mat-step-icon-state-edit {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }
  }
}
