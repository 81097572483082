.mat-mdc-card {
  box-sizing: content-box;
  font-size: 14px;
  padding: 16px;

  .mat-mdc-card-title {
    font-size: 18px;
  }

  .mat-mdc-card-content {
    font-size: 12px;

    .mat-mdc-card-subtitle {
      font-weight: 400;
      margin-bottom: 16px;
    }
  }

  .mat-mdc-card-actions {
    display: flex;
    justify-content: center;
  }
}

// If mat-tab is inside of a mat-card, the built in negative margins for mat-card-action causes clipping. Remove the negative margins.
mat-card {
  mat-tab-body {
    .mat-card-actions {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.card-like {
  border: 1px solid #e0e0e0;
  border-bottom-width: 0;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  display: block;
  padding: 16px;
  position: relative;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
