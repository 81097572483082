@use '@angular/material' as mat;
@use './variables' as v;
@use './palette' as p;

// roboto-300 - latin
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../../fonts/roboto-v20-latin-300.woff2') format('woff2'),
    url('../../fonts/roboto-v20-latin-300.woff') format('woff'),
    url('../../fonts/roboto-v20-latin-300.ttf') format('truetype'); // Safari, Android, iOS
}
// roboto-regular - latin
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('../../fonts/roboto-v20-latin-regular.woff2') format('woff2'),
    url('../../fonts/roboto-v20-latin-regular.woff') format('woff'),
    url('../../fonts/roboto-v20-latin-regular.ttf') format('truetype'); // Safari, Android, iOS
}
// roboto-500 - latin
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../../fonts/roboto-v20-latin-500.woff2') format('woff2'),
    url('../../fonts/roboto-v20-latin-500.woff') format('woff'),
    url('../../fonts/roboto-v20-latin-500.ttf') format('truetype'); // Safari, Android, iOS
}
// roboto-700 - latin
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../../fonts/roboto-v20-latin-700.woff2') format('woff2'),
    url('../../fonts/roboto-v20-latin-700.woff') format('woff'),
    url('../../fonts/roboto-v20-latin-700.ttf') format('truetype'); // Safari, Android, iOS
}

html,
.dashboard,
.classic,
.cdk-overlay-container {
  font-family: 'Roboto';
  font-size: v.$font-default-size;
}

%light-text {
  color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
}

%dark-text {
  color: mat.m2-get-color-from-palette(p.$mat-dashboard-black, p.$default-hue);
}

.light-text {
  @extend %light-text;
}

.dark-text {
  @extend %dark-text;
}

.faded-text {
  opacity: 0.5;
}
