$flexes: 10;
$break-small: 479px;
$break-header: 839px;
$break-large: 1024px;

.flex-row {
  display: flex;
  flex-direction: row;

  &.reverse {
    flex-direction: row-reverse;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;

  &.reverse {
    flex-direction: column-reverse;
  }
}

@for $i from 1 through $flexes - 1 {
  .flex-#{$i} {
    flex: $i;
  }
}

.container {
  padding-bottom: 32px;
}

// When doing media queries for responsiveness we need to use this mixin to keep consistency.
@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media == handhelds-landscape {
    @media only screen and (orientation: landscape) and (max-height: $break-small) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  }
}
