$white: #fff;
$black: #000;
$light-hue: 50;
$light-default-hue: 300;
$default-hue: 500;
$default-mid-hue: 600;
$grey-mid-hue: 700;
$dark-hue: 900;
$mid-hue: A100;

$card-shadow: (
  color-one: rgba(0, 0, 0, 0.2),
  color-two: rgba(0, 0, 0, 0.14),
  color-three: rgba(0, 0, 0, 0.12)
);

$mat-dashboard-dark-blue: (
  50: #2564c8,
  100: #2159b2,
  200: #1d4e9d,
  300: #194487,
  400: #153972,
  500: #112e5c,
  600: #0d2346,
  700: #091831,
  800: #050e1b,
  900: #010306,
  A100: #2e70d8,
  A200: #437edc,
  A400: #598de0,
  A700: #000,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-dashboard-mid-blue: (
  50: #89aada,
  100: #769cd4,
  200: #628ece,
  300: #4f80c8,
  400: #3c73c1,
  500: #3667ae,
  600: #305b9b,
  700: #2a5087,
  800: #244474,
  900: #1e3960,
  A100: #9db8e0,
  A200: #b0c6e6,
  A400: #c3d4ed,
  A700: #182d4d,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-dashboard-primary: (
  50: #94e2ba,
  100: #80ddae,
  200: #6cd8a1,
  300: #58d294,
  400: #44cd87,
  500: #34c37a,
  600: #2faf6d,
  700: #299b61,
  800: #248754,
  900: #1f7248,
  A100: #a8e8c7,
  A200: #bcedd4,
  A400: #d0f3e1,
  A700: #195e3b,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-dashboard-error: (
  50: #ed7274,
  100: #ea5c5d,
  200: #e84547,
  300: #e52e30,
  400: #de1c1e,
  500: #c7191b,
  600: #b01618,
  700: #9a1315,
  800: #831012,
  900: #6c0e0f,
  A100: #f0898a,
  A200: #f39fa0,
  A400: #f6b6b7,
  A700: #560b0c,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-dashboard-black: (
  50: #404040,
  100: #333,
  200: #262626,
  300: #1a1a1a,
  400: #0d0d0d,
  500: #000,
  600: #000,
  700: #000,
  800: #000,
  900: #000,
  A100: #4d4d4d,
  A200: #595959,
  A400: #666,
  A700: #000,

  contrast: (
    50: $white,
    100: $white,
    200: $white,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $white,
    A200: $white,
    A400: $white,
    A700: $white,
  )
);

$mat-dashboard-white: (
  50: #fff,
  100: #fff,
  200: #fff,
  300: #fff,
  400: #fff,
  500: #fff,
  600: #f2f2f2,
  700: #e6e6e6,
  800: #d9d9d9,
  900: #ccc,
  A100: #fff,
  A200: #fff,
  A400: #fff,
  A700: #bfbfbf,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $black,
    800: $black,
    900: $black,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $black,
  )
);

$mat-dashboard-light-grey: (
  50: #fff,
  100: #fff,
  200: #fff,
  300: #fff,
  400: #fdfdfd,
  500: #f0f0f0,
  600: #e3e3e3,
  700: #d6d6d6,
  800: #cacaca,
  900: #bdbdbd,
  A100: #fff,
  A200: #fff,
  A400: #fff,
  A700: #b0b0b0,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $black,
    800: $black,
    900: $black,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $black,
  )
);

$mat-dashboard-mid-grey: (
  50: #bebebe,
  100: #b1b1b1,
  200: #a4a4a4,
  300: #979797,
  400: #8b8b8b,
  500: #7e7e7e,
  600: #717171,
  700: #646464,
  800: #585858,
  900: #4b4b4b,
  A100: #cacaca,
  A200: #d7d7d7,
  A400: #e4e4e4,
  A700: #3e3e3e,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $black,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-dashboard-dark-grey: (
  50: #656565,
  100: #585858,
  200: #4b4b4b,
  300: #3e3e3e,
  400: #323232,
  500: #252525,
  600: #181818,
  700: #0b0b0b,
  800: #000,
  900: #000,
  A100: #717171,
  A200: #7e7e7e,
  A400: #8b8b8b,
  A700: #000,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-dashboard-success: (
  50: #02f87a,
  100: #02df6e,
  200: #02c661,
  300: #01ad55,
  400: #019348,
  500: #017a3c,
  600: #016130,
  700: #014723,
  800: #002e17,
  900: #00150a,
  A100: #17fd87,
  A200: #30fd94,
  A400: #49fea1,
  A700: #000,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-dashboard-confirmed: (
  50: #47ea77,
  100: #30e867,
  200: #1ae556,
  300: #17ce4d,
  400: #15b745,
  500: #12a03c,
  600: #0f8933,
  700: #0d722b,
  800: #0a5b22,
  900: #08441a,
  A100: #5eed88,
  A200: #75ef99,
  A400: #8cf2aa,
  A700: #052d11,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $black,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-dashboard-orange: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffab40,
  A200: #ff9100,
  A400: #ff9100,
  A700: #ff6d00,

  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $black,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $white,
  )
);

$mat-toaster-green: (
  500: #51a351
);

$mat-toaster-blue: (
  500: #112e5c
);
