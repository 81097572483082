@use '@angular/material' as mat;
@use '../palette' as p;

.dark {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-blue, p.$default-hue);
      padding: 0 4px;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          mat-label {
            color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
          }

          input::placeholder {
            font-size: 12px;
          }
        }
      }

      &.mdc-text-field--filled:not(.mdc-text-field--disabled) {
        .mdc-text-field__input {
          caret-color: currentColor;
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue, 0.75);
          font-family: 'Roboto';
        }
      }
    }
  }
}

input {
  &.mat-mdc-input-element {
    &::-webkit-calendar-picker-indicator {
      display: block;
    }
  }
}
