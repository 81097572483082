@use '@angular/material' as mat;
@use '../palette' as p;

.mat-mdc-snack-bar-container {
  &.success-snackbar {
    .mdc-snackbar__surface {
      background: mat.m2-get-color-from-palette(p.$mat-dashboard-primary, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }
  }

  &.error-snackbar {
    .mdc-snackbar__surface {
      background: mat.m2-get-color-from-palette(p.$mat-dashboard-error, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }
  }

  &.info-snackbar {
    .mdc-snackbar__surface {
      background: mat.m2-get-color-from-palette(p.$mat-toaster-blue, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }
  }

  simple-snack-bar {
    div {
      text-align: center;
    }
  }

  .mat-mdc-snack-bar-action {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-grey, p.$default-hue);
  }
}
