@use '@angular/material' as mat;
@use 'palette' as p;

.notification-view {
  box-sizing: border-box;
  counter-reset: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  font-size: 14pt;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  text-align: left;
  word-wrap: break-word;
  margin: 0;

  img {
    max-width: 100%;
  }
}

.mat-hint {
  display: block;
  font-size: 12px;
  font-weight: 400;
  height: 22px;
  letter-spacing: 0.4px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
}

.bulk-load-link {
  color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
  cursor: pointer;
}

