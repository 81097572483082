@use '@angular/material' as mat;
@use 'palette' as p;

button,
a {
  &[mat-raised-button] {
    &:not(.material-default-size) {
      padding: 0 40px;
    }

    &.medium {
      min-width: 90px;
      padding: 0 15px;
    }

    &.small {
      height: 30px;
      line-height: 30px;
      padding: 0 16px;
    }

    &.mini {
      height: 25px;
      line-height: 25px;
      padding: 0 8px;

      mat-icon {
        height: 18px;
        width: 18px;
      }
    }

    &.icon-button {
      mat-icon {
        cursor: pointer;
        padding-right: 3px;
        padding-top: 6px;
      }
    }

    &.blue {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }

    &.dark-blue {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-blue, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }

    &.red {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-error, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }

    &.green {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-primary, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }

    &.white {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
    }

    &.transparent {
      background-color: transparent;
    }

    &.disabled {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-grey, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }

    // Same styles as if [disabled] attribute present.
    // Use if you want the disabled style but don't want to actually disable the button.
    // e.g. a submit button you want to look disabled but still cause errors to show on untouched form elements when clicked.
    &.disabled-look {
      background-color: rgba(0, 0, 0, 0.12);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
    }
  }

  &[mat-button] {
    &.white {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
    }

    &.disabled-look {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
    }
  }

  &[mat-icon-button] {
    mat-icon {
      &:not(.default-cursor) {
        cursor: pointer;
      }
    }
  }

  &.cdk-program-focused {
    .mat-button-focus-overlay {
      background-color: transparent;
    }
  }

  // This removes the dotted border around the element at tabiindex 0 on Firefox.
  &::-moz-focus-inner {
    border: 0;
  }

  .mdc-button__label {
    letter-spacing: normal;

    img {
      vertical-align: middle;
    }
  }

  // The color should be white by default, this is added due to a bug: https://github.com/angular/components/issues/260
  &.mat-primary,
  &.mat-accent,
  &.mat-warn {
    &:not(.negative) {
      &:not(.mat-mdc-button)
      .mdc-button__label {
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
      }
    }
  }

  // The color should be white by default, this is added due to a bug: https://github.com/angular/components/issues/260
  &.mat-primary {
    &:not([disabled]) {
      &.mat-mdc-mini-fab {
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
      }
    }
  }

  &.mat-mdc-paginator-navigation-previous,
  &.mat-mdc-paginator-navigation-next,
  &.mat-mdc-paginator-navigation-first,
  &.mat-mdc-paginator-navigation-last {
    background-color: unset;
  }
}
