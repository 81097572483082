@use '@angular/material' as mat;
@use 'palette' as p;

@use 'palette';
@use 'layout';
@use 'theme';
@use 'sharedmixins';
@use 'sharedclasses';
@use 'typography/typography';
@use 'anchor/anchor';
@use 'button/button';
@use 'buttontoggle/buttontoggle';
@use 'card/card';
@use 'checkbox/checkbox';
@use 'chips/chips';
@use 'dialog/dialog';
@use 'draganddrop/draganddrop';
@use 'icon/icon';
@use 'input/input';
@use 'menu/menu';
@use 'progressbar/progressbar';
@use 'radio/radio';
@use 'select/select';
@use 'snackbar/snackbar';
@use 'stepper/stepper';
@use 'tab/tab';
@use 'table/table';
@use 'tooltip/tooltip';
@use 'formfield/formfield';

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;

  &.dashboard,
  &.classic {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

.full-width {
  width: 100%;
}

.color-mid-blue {
  color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
}
