@use 'typography/variables' as v;

.mat-mdc-table {
  th.mat-mdc-header-cell {
    color: v.$wash-out-color;
    font-size: 12px;
  }

  .mat-mdc-row {
    height: 48px;

    &:last-child {
      .mat-mdc-cell {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    &.mat-mdc-no-data-row {
      height: 48px;
    }

    .mat-mdc-cell {
      box-sizing: content-box;
      letter-spacing: normal;
      padding: 0;
      padding-left: 24px;
    }
  ;

    &:last-of-type {
      padding: 0 24px;
    }
  }
}
