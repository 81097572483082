@use '@angular/material' as mat;
@use 'palette' as p;

mat-button-toggle-group {
  &.vertical {
    flex-direction: column;
  }

  .mat-button-toggle-label-content {
    display: block;
  }

  .mat-button-toggle-checked {
    .mat-button-toggle-label-content {
      background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
      color: mat.m2-get-contrast-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
    }
  }
}
