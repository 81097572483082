@use '@angular/material' as mat;
@use 'palette' as p;
// For drag and drop functionality when a selectable element is selected a preview element is created that then follows
// the cursor. This preview element is appended to the body element, therefore it must be styled here, and cannot be
// styled within the component where the drag and drop has been implemented.

.cdk-drag-preview {
  &.report-chips-preview {
    align-items: center;
    cursor: all-scroll;
    display: flex;
    opacity: 0.5;
    width: 400px;

    .chip-order {
      bottom: 5px;
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-black, p.$default-hue);
      opacity: 0.54;
      position: relative;
      width: 25px;
    }

    .mat-mdc-chip {
      background: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
      cursor: all-scroll;
      display: flex;
      justify-content: space-between;
      line-height: 1.125;
      margin-bottom: 3px;
      width: 100%;

      @media (max-width: 459px) {
        width: 100%;
      }

      .remove-chip-icon {
        background: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-blue, p.$default-hue);
        border-radius: 50%;
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        cursor: all-scroll;
        font-size: 12px;
        height: 16px;
        min-width: 16px;
        opacity: 0.7;
        padding: 4px 2px 0;
        text-align: center;
        width: 16px;
      }
    }
  }
}
