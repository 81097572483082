@use '@angular/material' as mat;
@use '../palette' as p;

.mdc-linear-progress__buffer {
  .mdc-linear-progress__buffer-bar {
    background-color: #769cd4;
  }
}

.mdc-linear-progress__bar {
  .mdc-linear-progress__bar-inner {
    border-color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
  }
}
