@use '@angular/material' as mat;
@use 'palette' as p;
@use 'sass:map';

.ng-star-inserted {
  .mat-mdc-tooltip {
    .mdc-tooltip__surface,
    .mdc-tooltip__surface-animation {
      background-color: map.get(mat.$m2-light-theme-background-palette, tooltip);
      color: p.$white;
    }
  }
}
