$font-extra-large-size: 32px;
$font-large-size: 24px;
$font-medium-size: 20px;
$font-default-size: 14px;
$font-small-size: 12px;
$font-extra-small-size: 10px;
$font-small-mid-size: 16px;

$font-regular-weight: 400;
$font-medium-weight: 500;
$font-bold-weight: 700;
$row-height: 30px;

$widget-label-opacity: 0.6;

$z-index: (
  report-table-display-default: 100,
  app-data-table-display-0: 200,
  app-data-table-display-1: 201,
  report-component: 300,
  report-component-1: 301,
  report-component-2: 302,
  report-component-3: 303,
  report-dropdown: 304,
  app-component-0: 400
);

$datatable-colors: (
  body-text-color: #222,
  header-text-color: #555,
  border-color: #d9d9d9,
  transparent: rgba(0, 0, 0, 0)
);

$wash-out-color: rgba(0, 0, 0, 0.55);
