@use '@angular/material' as mat;
@use 'palette' as p;
// see https://google.github.io/material-design-icons/#setup-method-2-self-hosting
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/material-icons-v67.woff2') format('woff2'),
    url('../../fonts/material-icons-v67.woff') format('woff');
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';

  // Support for IE.
  font-feature-settings: 'liga';
  // Preferred icon size, size to use as documented by material
  font-size: 24px;

  // Support for all WebKit browsers.
  -webkit-font-smoothing: antialiased;

  // Support for Firefox.
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  //Support for Safari and Chrome.
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;

  word-wrap: normal;
}

mat-icon {
  &.validate-failure,
  &.red {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-error, p.$default-hue);
  }

  &.validate-success {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-primary, p.$default-hue);
  }

  &.report-icon {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-black, p.$default-hue);
  }

  &.blue {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
  }

  &.dark-blue {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-blue, p.$default-hue);
  }

  &.disabled-icon {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-grey, p.$default-hue);
  }
}

.mat-form-field-disabled {
  mat-icon {
    cursor: default;
  }
}
