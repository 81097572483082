@use '@angular/material' as mat;
@use '../palette' as p;
@use '../typography/variables';

body {
  &.dashboard,
  .classic,
  .cdk-overlay-container {
    .cdk-overlay-pane {
      ::-webkit-scrollbar {
        height: 16px;
        width: 16px;
      }

      ::-webkit-scrollbar-thumb {
        background: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-blue, p.$default-hue);
        background-clip: padding-box;
        border: 4px solid transparent;
        border-radius: 8px;
        height: 40px;
      }

      ::-webkit-scrollbar-track {
        border-radius: 8px;
      }

      &.dark {
        ::-webkit-scrollbar {
          height: 24px;
          width: 16px;
        }

        ::-webkit-scrollbar-thumb {
          background: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-grey, p.$default-hue);
          background-clip: padding-box;
          border: 4px solid transparent;
          border-radius: 8px;
          height: 40px;
        }

        ::-webkit-scrollbar-track {
          border-radius: 8px;
        }

        .mat-mdc-dialog-surface {
          background: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-blue, p.$default-hue);
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
          font-family: 'Roboto';
          margin-left: auto;
        }

        .mat-mdc-dialog-container {
          .mdc-dialog__content {
            color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
            font-size: 14px;
            padding-top: 0;
          }

          .mdc-dialog__title {
            color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
            font: 400 24px/32px Roboto,Helvetica Neue,sans-serif;
            letter-spacing: normal;
            margin: 0 0 10px;
            text-align: center;
          }
        }
      }

      &.selection-dialog-panel {
        .mat-mdc-dialog-surface {
          background: mat.m2-get-color-from-palette(p.$mat-dashboard-black, p.$default-hue);
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        }
      }

      .mat-mdc-dialog-container {
        position: relative;

        .mdc-dialog__content {
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-black, p.$default-hue);
          padding-top: 0;
        }

        .modal-action-buttons {
          bottom: 30px;
          position: absolute;
          right: 30px;
        }

        .search-inputs {
          .mat-mdc-form-field {
            .mdc-line-ripple {
              &::before {
                border-bottom-color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
              }
            }
          }

        }

      }

    }
  }

  .full-screen-modal-overlay {
    .mat-mdc-dialog-surface {
      background: mat.m2-get-color-from-palette(p.$mat-dashboard-light-grey, p.$default-hue);
      padding: 24px;
    }

    .table-wrapper {
      max-height: 95%;
    }
  }
}

.sub-header-color {
  color: mat.m2-get-color-from-palette(p.$mat-dashboard-mid-grey, p.$default-hue);
}

.chips-modal-overlay {
  mat-mdc-dialog-surface {
    padding-bottom: 0;
  }
}

.clb-copy-link-modal-overlay {
  @media (max-width: 700px) {
    height: 95% !important;
    max-width: none !important;
    width: 99% !important;
  }
}

.customize-columns-panel {
  .mat-mdc-dialog-container {
    .mdc-dialog__content {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    }

    .mdc-list-item__start {
      margin-right: 0;
    }
  }
}
