mat-form-field {
  &:not(.mat-mdc-paginator-page-size-select) {
    margin-bottom: 12px;

    .mdc-text-field {
      padding-left: 4px;
    }

    .mdc-text-field--filled {
      &:not(.mdc-text-field--disabled) {
        background-color: transparent;
      }
    }

    .mdc-text-field--disabled.mdc-text-field--filled {
      background-color: transparent;
    }

    .mat-mdc-form-field-hint-wrapper {
      padding-left: 0;
    }

    .mat-mdc-form-field-error-wrapper {
      padding-left: 0;
    }
  }
}
