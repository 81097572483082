@use '@angular/material' as mat;
@use 'palette' as p;

.mobile-menu-panel {
  &.mat-mdc-menu-panel {
    background: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-blue, p.$default-hue);
    left: 10px;
    position: relative;
    top: 10px;
    width: 220px;
  }

  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      .mat-mdc-menu-item-text {
        color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        font-size: 14px;
      }

      svg {
        &.mat-mdc-menu-submenu-icon {
          color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
        }
      }
    }
  }

  mat-divider {
    border-top-color: rgb(255, 255, 255, 0.5);
    margin: 0 15px;
  }

  // start: When in classic, php adds style that affects a links. Remove here.
  a:active {
    outline: unset;
  }

  a:hover,
  a:focus {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-mid-hue);
    outline: unset;
    outline-offset: unset;
    text-decoration: unset;
  }

  img {
    /* stylelint-disable declaration-property-value-disallowed-list */
    border: none;
    /* stylelint-enable declaration-property-value-disallowed-list */
    vertical-align: baseline;
  }
  // end
}

.report-menu-panel {
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      .mat-mdc-menu-item-text {
        font-size: 14px;
      }
    }
  }

  &.mat-mdc-menu-panel {
    background: mat.m2-get-color-from-palette(p.$mat-dashboard-dark-blue, p.$default-hue);
    left: 20px;
    position: relative;
    width: 180px;
  }

  &.hamburger-menu-reports {
    left: 0;
    top: 8px;
  }

  .mat-mdc-menu-item-text {
    color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    height: 36px;
    line-height: 36px;

    .mdc-list-item__primary-text {
      color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
    }
  }
}

.mobile-menu-overlay {
  background-color: mat.m2-get-color-from-palette(p.$mat-dashboard-black, p.$default-hue, 0.7);
}
