@use '@angular/material' as mat;
@use 'palette' as p;

.classic,
.dashboard {
  div {
    &.mat-mdc-tab-nav-bar,
    &.mat-mdc-tab-links {
      border: 0;
      max-width: 1000px;
      width: 100%;

      mat-ink-bar {
        display: none;
      }

      &.mat-mdc-tab-links {
        border: 0;
        display: flex;

        a {
          &.mat-mdc-tab-link,
          &:hover {
            border: 0;
            color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
            flex: 1;
            height: 40px;
            min-width: 0;
            opacity: 1;

            &:first-child {
              max-width: 72px;
              min-width: 72px;
            }

            &[ng-reflect-active='true'],
            &.active {
              &:not(:first-child) {
                &::after {
                  border-bottom: 10px solid mat.m2-get-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  bottom: 0;
                  content: '';
                  position: absolute;
                  right: 40px;
                }
              }

              &:first-child {
                background-color: transparent;
                color: mat.m2-get-contrast-color-from-palette(p.$mat-dashboard-mid-blue, p.$default-hue);
              }
            }

            span.mdc-tab__content {
              span.mdc-tab__text-label {
                color: mat.m2-get-color-from-palette(p.$mat-dashboard-white, p.$default-hue);
                letter-spacing: normal;
              }
            }
          }
        }
      }
    }
  }
}
